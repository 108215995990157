<template>
  <div class="order-flow-layout">
    <slot />
    <app-toast-wrapper />
  </div>
</template>

<script setup>
</script>

<style lang="scss">
:root {
  --order-flow-layout-padding: var(--spacing-default);
  --order-flow-layout-padding-desktop: var(--spacing-larger);
}

.order-flow-layout {
  padding: var(--order-flow-layout-padding);
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;

  @include breakpoint(md) {
    padding: var(--order-flow-layout-padding-desktop)
  }
}
</style>
